import React from "react";
import Breadcrumbs from '../components/Breadcrumbs';
import Quote from '../components/Quote';
import Image from '../landingnewcomponents/Image/Landing';
import Paragraph from '../landingnewcomponents/Paragraph/Landing';
import Section from '../landingnewcomponents/Section/Landing';
import Title, { TITLE_COLORS } from '../landingnewcomponents/Title/Landing';
import Layout from '../layouts/layout';
import './../common/styles.scss';

const AboutUsPage = (props) => {

    return (
        <Layout location={props.location}>
            <Section>
                <Breadcrumbs
                    items={[{ title: "Viewst", url: "/" }, { title: "Us", url: "/us/" }]}
                />
                <Title color={TITLE_COLORS.Violet}>Us</Title>
                <Quote
                    author="Victoria Duben, CEO and founder of Viewst"
                >Helping people stay focused on things that matter most in life is what drives us.</Quote>
                <Paragraph fluid>
                    We respect our customers’ time. That’s why we built an incredibly powerful tool that helps them create high quality banners that can be used on numerous platforms in minutes. Our mission is to bring the best user experience to everyone. We are committed to providing the latest technologies and capabilities of machine learning algorithms to enable anyone to create professionally and beautifully designed ads in bulk. We are aiming to transform graphic content production and give our customers peace of mind that their creative project will be sure to go off without a hitch. With VIEWST, even users who have absolutely no design experience can create professionally designed ads of any kind from scratch. Our priority is to give our clients the power to optimize their efficiency and make their lives easier by using our product. That's why we designed the simplified and user-friendly technology that is at the center of VIEWST.com. We believe advertising content that’s targeted to the right user should attract attention through delight and not just because it’s a box that won’t close. Our product exists and matters for those who want to create 10x more banners, earn 10x more money. By eliminating bottlenecks in creative and production communication while also reducing time and cutting costs for production of marketing materials, VIEWST has become a game changer for designers, marketers and agencies alike.

                </Paragraph>
            </Section>
            <Section>
                <Title color={TITLE_COLORS.Violet}>About</Title>
                <Paragraph fluid>
                    VIEWST is the brainchild of our Founder, Victoria Duben. The idea was born in the U.S. after a year and a half of building a mono-product for premium advertisers - floating animated ad banners. We found that the world’s largest brands were always looking for innovative ideas for their advertising campaigns. These big ideas often require much more complex animations. Following a brief period of reflection, we realized that for us to generate more sophisticated ad units we needed access to a wide variety of tools and platforms to get the job done. This was our Ah-Ha moment that made us decide to combine industry standard software such as Adobe, Figma, Canva, etc. into a single interface: VIEWST. Since then, we were able to generate banners with numerous animation styles, increase our efficiency by 10x, and make our design team’s workload much easier to manage. After having regular DesignTalks with other design teams, we realized that they too had similar issues with generating web banners and video ads. It was obvious what we had to do. As the design world was in high need of a super tool to solve all pains in design production, we decided to create VIEWST to make creative ad and banner design easy and fun.
                </Paragraph>
            </Section>
            <Section>
                <Title color={TITLE_COLORS.Violet}>Team</Title>
                <Paragraph fluid>
                    Our team is limitless in everything we do, wherever we are. We believe that creativity is a team sport. That’s why we carefully selected brilliant and energetic individuals who are ready to create a life where they are connected to a higher purpose and have a reason to jump out of bed in the morning and seize the day. We are a team of 18 very unique and motivated individuals spread around the world on different continents from the U.S. to Africa and Asia. Our ultimate goal of becoming the #1 global design app for everyone keeps us focused and connected. At VIEWST, we don’t measure our success in millions of active users. We measure our success in how our app helps make people’s lives easier and makes them happy. We give our all to help our customers optimize the production of ad banners so they can focus on the things that matter most. The idea of “you are not a designer unless you have the right app to do the job” drives us to create amazing features that empower those with no design skills to create something unique with ease.
                </Paragraph>
                <div>
                    <Image src="/images/team.png" />
                </div>
            </Section>
            <Section>
                <Title color={TITLE_COLORS.Violet}>Culture</Title>
                <Paragraph fluid>
                    As a remote team of mostly millennials, it shouldn’t be a surprise that VIEWST’s corporate culture is all about passion and self-direction. Our unique work environment is home to a diverse group of professionals on 4 continents: Europe, Africa, USA and Asia. The founding team is incredibly proud of the autonomy and self-management of every one of our team members. In addition to being motivated and self-driven, our team enjoys working together in a collaborative nature. We bring passion and innovation to our product that many people, including our internal design team, love to use.
                </Paragraph>
            </Section>
        </Layout>
    )
}

export default AboutUsPage;
