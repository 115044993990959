import { cn } from '@bem-react/classname';
import React from 'react';
import './paragraph.scss';


const Paragraph = ({fluid, children}) => {
    const paragraphClass = cn('Paragraph');

    return (<p className={paragraphClass('', {fluid})}>{children}</p>);
};

export default Paragraph
