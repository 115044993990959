import { cn } from '@bem-react/classname';
import React from 'react';
import './quote.scss';

/**
 *
 * @param {Object} props
 *
 * @returns
 */
const Quote = ({author, children}) => {
    const quoteClass = cn('Quote');
    return <div className={quoteClass()}>
        <div className={quoteClass('Text')}>{children}</div>
        <div className={quoteClass('Author')}>{author}</div>
    </div>
}

export default Quote;
