import { cn } from '@bem-react/classname';
import React from 'react';
import './image.scss';

const classItem = cn('Image');

const Image = ({ sSizeSrc, mSizeSrc, lSizeSrc, src, alt = '', ...props}) => {
    return <picture className={classItem()}>
        {sSizeSrc ? <source media="(max-width: 1023px)" srcSet={sSizeSrc}/> : null}
        {mSizeSrc ? <source media="(max-width: 1359px)" srcSet={mSizeSrc}/> : null}
        <img {...props}
            src={lSizeSrc || src}
            alt={alt}
               />
    </picture>
}

export default Image;
